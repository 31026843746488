import {initializeLocaleWith} from "@/setup/setupi18n"
import {resetStores} from "@/library/stores/destroyable"
import {useUserStore} from "@/library/stores/user"
import {useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import * as Sentry from "@sentry/browser"

export async function logout() {
  await initializeLocaleWith(undefined) // reset to `en`; multilingual is currently behind an org-based feature flag
  useUserStore().removeToken()
  resetStores()

  // ordering matters here; otherwise `resetStores()` resets isLoaded to false, pausing metrics tracking
  useMetricsDataPointStore().pull()

  Sentry.setUser(null)
  Sentry.setTag("app.userId", null)
  Sentry.setTag("app.userHasCadenceEmailAddress", null)
  Sentry.setTag("app.userHasDetectifyEmailAddress", null)
}
