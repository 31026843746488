import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const INSURANCE_PATH = "insurance"

export const INSURANCE_LABEL_MAP: LabelMap = new Map([
  ["insurance[*].type", ""],
  ["insurance[*].name", ""],
  ["insurance[*].id", ""],
  ["insurance[*].attachment", ""],
  ["insurance[*].notes", ""],
])

export const INSURANCE_FILTER_CONTAINER_NAMES = [
  "insurance__life__northwestern_mutual",
  "insurance__life__massmutual",
  "insurance__life__new_york_life",
  "insurance__life__prudential",
  "insurance__life__lincoln_financial",
  "insurance__life__john_hancock",
  "insurance__life__pacific_life",
  "insurance__life__corebridge_financial",
  "insurance__life__midland_national",
  "insurance__life__nationwide",
  "insurance__life__pennmutual",
  "insurance__life__equitable",
  "insurance__life__national_life",
  "insurance__life__manulife",
  "insurance__life__industrial_alliance",
  "insurance__life__bmo",
  "insurance__life__ivari",
]

export const INSURANCE_FILTER_life_northwestern_mutual = new Map([
  ["insurance__life__northwestern_mutual[*].id", ""],
  ["insurance__life__northwestern_mutual[*].attachment", ""],
  ["insurance__life__northwestern_mutual[*].notes", ""],
  ["insurance__life__northwestern_mutual[*].type", ""],
  ["insurance__life__northwestern_mutual[*].name", ""],
])
export const INSURANCE_FilTER__life__massmutual = new Map([
  ["insurance__life__massmutual[*].id", ""],
  ["insurance__life__massmutual[*].attachment", ""],
  ["insurance__life__massmutual[*].notes", ""],
  ["insurance__life__massmutual[*].type", ""],
  ["insurance__life__massmutual[*].name", ""],
])
export const INSURANCE_FilTER__life__new_york_life = new Map([
  ["insurance__life__new_york_life[*].id", ""],
  ["insurance__life__new_york_life[*].attachment", ""],
  ["insurance__life__new_york_life[*].notes", ""],
  ["insurance__life__new_york_life[*].type", ""],
  ["insurance__life__new_york_life[*].name", ""],
])
export const INSURANCE_FilTER__life__prudential = new Map([
  ["insurance__life__prudential[*].id", ""],
  ["insurance__life__prudential[*].attachment", ""],
  ["insurance__life__prudential[*].notes", ""],
  ["insurance__life__prudential[*].type", ""],
  ["insurance__life__prudential[*].name", ""],
])
export const INSURANCE_FilTER__life__lincoln_financial = new Map([
  ["insurance__life__lincoln_financial[*].id", ""],
  ["insurance__life__lincoln_financial[*].attachment", ""],
  ["insurance__life__lincoln_financial[*].notes", ""],
  ["insurance__life__lincoln_financial[*].type", ""],
  ["insurance__life__lincoln_financial[*].name", ""],
])
export const INSURANCE_FilTER__life__john_hancock = new Map([
  ["insurance__life__john_hancock[*].id", ""],
  ["insurance__life__john_hancock[*].attachment", ""],
  ["insurance__life__john_hancock[*].notes", ""],
  ["insurance__life__john_hancock[*].type", ""],
  ["insurance__life__john_hancock[*].name", ""],
])
export const INSURANCE_FilTER__life__pacific_life = new Map([
  ["insurance__life__pacific_life[*].id", ""],
  ["insurance__life__pacific_life[*].attachment", ""],
  ["insurance__life__pacific_life[*].notes", ""],
  ["insurance__life__pacific_life[*].type", ""],
  ["insurance__life__pacific_life[*].name", ""],
])
export const INSURANCE_FilTER__life__corebridge_financial = new Map([
  ["insurance__life__corebridge_financial[*].id", ""],
  ["insurance__life__corebridge_financial[*].attachment", ""],
  ["insurance__life__corebridge_financial[*].notes", ""],
  ["insurance__life__corebridge_financial[*].type", ""],
  ["insurance__life__corebridge_financial[*].name", ""],
])
export const INSURANCE_FilTER__life__midland_national = new Map([
  ["insurance__life__midland_national[*].id", ""],
  ["insurance__life__midland_national[*].attachment", ""],
  ["insurance__life__midland_national[*].notes", ""],
  ["insurance__life__midland_national[*].type", ""],
  ["insurance__life__midland_national[*].name", ""],
])
export const INSURANCE_FilTER__life__nationwide = new Map([
  ["insurance__life__nationwide[*].id", ""],
  ["insurance__life__nationwide[*].attachment", ""],
  ["insurance__life__nationwide[*].notes", ""],
  ["insurance__life__nationwide[*].type", ""],
  ["insurance__life__nationwide[*].name", ""],
])
export const INSURANCE_FilTER__life__pennmutual = new Map([
  ["insurance__life__pennmutual[*].id", ""],
  ["insurance__life__pennmutual[*].attachment", ""],
  ["insurance__life__pennmutual[*].notes", ""],
  ["insurance__life__pennmutual[*].type", ""],
  ["insurance__life__pennmutual[*].name", ""],
])
export const INSURANCE_FilTER__life__equitable = new Map([
  ["insurance__life__equitable[*].id", ""],
  ["insurance__life__equitable[*].attachment", ""],
  ["insurance__life__equitable[*].notes", ""],
  ["insurance__life__equitable[*].type", ""],
  ["insurance__life__equitable[*].name", ""],
])
export const INSURANCE_FilTER__life__national_life = new Map([
  ["insurance__life__national_life[*].id", ""],
  ["insurance__life__national_life[*].attachment", ""],
  ["insurance__life__national_life[*].notes", ""],
  ["insurance__life__national_life[*].type", ""],
  ["insurance__life__national_life[*].name", ""],
])
export const INSURANCE_FilTER__life__manulife = new Map([
  ["insurance__life__manulife[*].id", ""],
  ["insurance__life__manulife[*].attachment", ""],
  ["insurance__life__manulife[*].notes", ""],
  ["insurance__life__manulife[*].type", ""],
  ["insurance__life__manulife[*].name", ""],
])
export const INSURANCE_FilTER__life__industrial_alliance = new Map([
  ["insurance__life__industrial_alliance[*].id", ""],
  ["insurance__life__industrial_alliance[*].attachment", ""],
  ["insurance__life__industrial_alliance[*].notes", ""],
  ["insurance__life__industrial_alliance[*].type", ""],
  ["insurance__life__industrial_alliance[*].name", ""],
])
export const INSURANCE_FilTER__life__bmo = new Map([
  ["insurance__life__bmo[*].id", ""],
  ["insurance__life__bmo[*].attachment", ""],
  ["insurance__life__bmo[*].notes", ""],
  ["insurance__life__bmo[*].type", ""],
  ["insurance__life__bmo[*].name", ""],
])
export const INSURANCE_FilTER__life__ivari = new Map([
  ["insurance__life__ivari[*].id", ""],
  ["insurance__life__ivari[*].attachment", ""],
  ["insurance__life__ivari[*].notes", ""],
  ["insurance__life__ivari[*].type", ""],
  ["insurance__life__ivari[*].name", ""],
])
