import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"

export const ACCOUNTS_PATH = "account"

export const ACCOUNTS_LABEL_MAP: LabelMap = new Map([
  ["account[*].type", ""],
  ["account[*].name", ""],
  ["account[*].id", ""],
  ["account[*].email", ""],
  ["account[*].notes", ""],
])

export const ACCOUNT_FILTER_CONTAINER_NAMES = [
  "account__digital_account__paypal",
  "account__digital_account__google",
  "account__digital_account__facebook",
  "account__digital_account__instagram",
  "account__digital_account__linkedin",
  "account__digital_account__pinterest",
  "account__digital_account__twitter",
  "account__digital_account__flickr",
]

export const ACCOUNT_FILTER__digital_account__paypal = new Map([
  ["account__digital_account__paypal[*].id", ""],
  ["account__digital_account__paypal[*].email", ""],
  ["account__digital_account__paypal[*].notes", ""],
  ["account__digital_account__paypal[*].type", ""],
  ["account__digital_account__paypal[*].name", ""],
])
export const ACCOUNT_FILTER__digital_account__google = new Map([
  ["account__digital_account__google[*].id", ""],
  ["account__digital_account__google[*].email", ""],
  ["account__digital_account__google[*].notes", ""],
  ["account__digital_account__google[*].type", ""],
  ["account__digital_account__google[*].name", ""],
])
export const ACCOUNT_FILTER__digital_account__facebook = new Map([
  ["account__digital_account__facebook[*].id", ""],
  ["account__digital_account__facebook[*].email", ""],
  ["account__digital_account__facebook[*].notes", ""],
  ["account__digital_account__facebook[*].type", ""],
  ["account__digital_account__facebook[*].name", ""],
])
export const ACCOUNT_LABEL_MAP__digital_account__instagram = new Map([
  ["account__digital_account__instagram[*].id", ""],
  ["account__digital_account__instagram[*].email", ""],
  ["account__digital_account__instagram[*].notes", ""],
  ["account__digital_account__instagram[*].type", ""],
  ["account__digital_account__instagram[*].name", ""],
])
export const ACCOUNT_FILTER__digital_account__linkedin = new Map([
  ["account__digital_account__linkedin[*].id", ""],
  ["account__digital_account__linkedin[*].email", ""],
  ["account__digital_account__linkedin[*].notes", ""],
  ["account__digital_account__linkedin[*].type", ""],
  ["account__digital_account__linkedin[*].name", ""],
])
export const ACCOUNT_LABEL_MAP__digital_account__pinterest = new Map([
  ["account__digital_account__pinterest[*].id", ""],
  ["account__digital_account__pinterest[*].email", ""],
  ["account__digital_account__pinterest[*].notes", ""],
  ["account__digital_account__pinterest[*].type", ""],
  ["account__digital_account__pinterest[*].name", ""],
])
export const ACCOUNT_FILTER__digital_account__twitter = new Map([
  ["account__digital_account__twitter[*].id", ""],
  ["account__digital_account__twitter[*].email", ""],
  ["account__digital_account__twitter[*].notes", ""],
  ["account__digital_account__twitter[*].type", ""],
  ["account__digital_account__twitter[*].name", ""],
])
export const ACCOUNT_FILTER__digital_account__flickr = new Map([
  ["account__digital_account__flickr[*].id", ""],
  ["account__digital_account__flickr[*].email", ""],
  ["account__digital_account__flickr[*].notes", ""],
  ["account__digital_account__flickr[*].type", ""],
  ["account__digital_account__flickr[*].name", ""],
])
