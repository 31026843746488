import {AnalyticsBrowser} from "@segment/analytics-next"
import type {IAuthUser} from "@/library/models/auth-user.interface"
import {type App, watch} from "vue"
import {type IMetricsDataPointContext, useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import {useUserStore} from "@/library/stores/user"
import {createPageConfigFor} from "@/setup/setupGtag"
import {find} from "lodash"
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordNormalized,
} from "vue-router"
import {createLogger} from "@/library/domain/logger"

export async function setupSegment(app: App) {
  if (!import.meta.env.VITE_SEGMENT_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/setupSegment.ts",
      "Skipping initialization of Segment due to missing VITE_SEGMENT_KEY",
    )

    return
  }

  const {$router: router} = app.config.globalProperties
  const metricsDataPointStore = useMetricsDataPointStore()
  analytics = fetchLibrary()

  watch(
    () => metricsDataPointStore.context,
    (point: IMetricsDataPointContext) => identifySegmentUser(useUserStore().user, point),
    {flush: "sync"},
  )

  // ensure track is invoked at least once with all context populated
  await metricsDataPointStore.isLoaded()
  identifySegmentUser(useUserStore().user, metricsDataPointStore.context)
  trackPage(router.currentRoute.value, router.currentRoute.value.redirectedFrom)

  // @duplicated from @/enduser/setup/setupGtag
  router.beforeResolve(
    async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
      next() // next early; no needs to block router chain of responsibility

      // normalized route meta is cumulative; instead, introspect raw route definition from matched
      const routeDef = find(to.matched, {name: to.name}) as undefined | RouteRecordNormalized
      if (routeDef?.meta?.ignoreMetrics) {
        return // explicit opt-out for layouts, etc.
      }

      await metricsDataPointStore.isLoaded()
      trackPage(to, from)
    },
  )
}

export function fetchLibrary() {
  return AnalyticsBrowser.load({writeKey: import.meta.env.VITE_SEGMENT_KEY})
}

export function identifySegmentUser(user?: null | IAuthUser, context?: IMetricsDataPointContext) {
  analytics?.identify(
    `${user?.id || ""}`,
    {
      email: `${user?.email || ""}`,
      username: `${user?.email || ""}`,

      ...context,
    },
    {
      integrations: {
        Intercom: {user_hash: user?.intercom_hash || null},
      },
    },
  )
}

function trackPage(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
  const dest = createPageConfigFor(to)
  analytics?.page({
    name: dest.screen_name,
    path: dest.page_path,
    title: dest.page_title,
    search: window.location.search,
    referrer: document.referrer,
    referrer_name: from ? createPageConfigFor(from).screen_name : null,

    ...(to.meta?.metric || {}),
  })
}

// populated above during setup; this will get replaced by a module-level `track()`
let analytics: null | AnalyticsBrowser = null

export default analytics
