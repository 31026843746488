import {defineStore} from "pinia"
import type {VueCookies} from "vue-cookies"
import Cookies from "vue-cookies"
import axios from "axios"
import type {IAuthUser} from "@/library/models/auth-user.interface"
import type {
  IChangePassword,
  ILogin,
  IResetPassword,
  ISignup,
  ITFACode,
  SignUpType,
} from "@/library/models/security.interface"
import {ROLE_ID} from "@/library/models/role.interface"
import {AuthUserApi} from "@/library/api/auth-user"
import type {IUserFeedback} from "@/library/models/user.interface"
import {merge} from "lodash"
import {createLogger} from "@/library/domain/logger"
import * as Sentry from "@sentry/browser"
import {AUTH_TOKEN_EXPIRATION_MINUTES} from "@/setup/setupAuthExpiration"
import {initializeLocaleWith} from "@/setup/setupi18n"

interface State {
  user: IAuthUser | null
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: null,
  }),
  getters: {
    userFirstName: state => (state.user ? state.user.first_name : ""),
    userName: state => (state.user ? state.user.name : ""),
    isOrgUser: state =>
      state.user && state.user.role
        ? state.user.role.id === ROLE_ID.BUSINESS_USER ||
          state.user.role.id === ROLE_ID.BUSINESS_ADMIN ||
          state.user.role.id === ROLE_ID.ENTERPRISE_ADMIN
        : false,
    hasViewedPrePlanningOnBoarding: state => state.user?.meta?.has_seen_pre_planner_on_boarding_modal,
    hasViewedExecutorAssistantOnBoarding: state => state.user?.meta?.has_seen_onboarding_modal,
    hasViewedRoadmapModal: state => state.user?.meta?.has_seen_roadmap_modal,
  },
  actions: {
    async fetchAuthUser() {
      this.user = null // relies on the fact that we've a single call site for this method in a given user flow
      try {
        const user = await AuthUserApi.fetch()

        // highjack the time-space between knowing user and exposing user to the app as indication of "loaded"
        await initializeLocaleWith(user.meta?.preferred_locale)
        this.user = user
      } catch (e) {
        createLogger().warn("library/stores/user", "Failed to fetch auth user", {extra: {e}})
      }

      // todo: push these into `setup/setupSentry.ts` using pattern from setupGtag and setupClarity
      Sentry.setUser({
        id: this.user?.id,
        username: this.user?.email,
        email: this.user?.email,
        hasCadenceEmailAddress:
          this.user?.email.includes("cadencecares.ca") || this.user?.email.includes("cadenceco.com"),
        hasDetectifyEmailAddress:
          this.user?.email.includes("detectify.com") || this.user?.email.includes("detectify_bot"),
      })
      Sentry.setTag("app.userId", this.user?.id)
      Sentry.setTag(
        "app.userHasCadenceEmailAddress",
        this.user?.email.includes("cadencecares.ca") || this.user?.email.includes("cadenceco.com"),
      )
      Sentry.setTag(
        "app.userHasDetectifyEmailAddress",
        this.user?.email.includes("detectify.com") || this.user?.email.includes("detectify_bot"),
      )
    },

    async updateUser(userToUpdate: Partial<IAuthUser>) {
      await axios.put(`/v2/auth/user`, userToUpdate).then(response => {
        this.$patch({user: response.data.data})
      })
    },

    patchLocalState(patchData: any) {
      merge(this.user, patchData)
    },

    async submitFeedback(feedback: IUserFeedback) {
      await axios.post(`/v2/auth/submit_feedback`, feedback)
    },

    async changePassword(data: IChangePassword) {
      await axios.post(`/v2/auth/change_password`, data)
    },

    async sendResetPasswordEmail(email: string) {
      await axios.post("/v2/auth/send_reset_password_email", {email})
    },

    async updateUserTfa(tfaType: IAuthUser["tfa_type"]) {
      await axios.put("/v2/auth/user/tfa", {tfa_type: tfaType}).then(response => {
        this.$patch({user: response.data.data})
      })
    },

    async sendPhoneVerificationCode() {
      await axios.post("/v2/auth/send_phone_verification_code").then(response => {
        this.$patch({user: response.data.data})
      })
    },

    async verifyPhoneVerificationCode(code: string) {
      await axios.post("/v2/auth/verify_phone_verification_code", {code}).then(response => {
        this.$patch({user: response.data.data})
      })
    },

    async login(data: ILogin) {
      await axios.post("/v2/auth/login", data).then(response => {
        this.setToken(response.data.token)

        this.$patch({
          user: response.data.user,
        })
      })
    },

    delete() {
      return axios.delete("/v2/auth/user")
    },

    async resetPassword(data: IResetPassword) {
      await axios.post("/v2/auth/reset_password", data)
    },

    async signup(signupType: SignUpType, data: ISignup) {
      await axios.post(`/v2/auth/signup/${signupType}`, data).then(response => {
        this.setToken(response.data.token)

        this.$patch({
          user: response.data.user,
        })
      })
    },

    async verifyTFA(data: ITFACode) {
      await axios.post("/v2/auth/verify-tfa", data).then(response => {
        this.setToken(response.data.token)

        this.$patch({
          user: response.data.user,
        })
      })
    },

    async resendTfa(tfaToken: ITFACode["token"], tfaType: "phone" | "email") {
      await axios.post("/v2/auth/resend-tfa", {token: tfaToken, tfa_type: tfaType})
    },

    removeToken() {
      this.setToken(null)
    },

    getToken() {
      return (Cookies as unknown as VueCookies).get("end-user-token")
    },

    setToken(token: string | null) {
      const cookies = Cookies as unknown as VueCookies
      if (token) {
        cookies.set("end-user-token", token, AUTH_TOKEN_EXPIRATION_MINUTES)
      } else {
        cookies.remove("end-user-token")
      }
    },

    refreshTokenExpiration() {
      this.setToken(this.getToken())
    },
  },
})
