import type {App} from "vue"
import {t} from "i18next"
import {useNotificationsStore} from "@/library/stores/notifications"
import type {Router} from "vue-router"
import {find} from "lodash"
import {ROUTE_NAME_AUTHENTICATED_ROOT, ROUTE_NAME_NOT_AUTHENTICATED_ROOT} from "@/router"
import {logout} from "@/executor-assistant/domain/auth"
import {createLogger} from "@/library/domain/logger"

export default function setupRouting(app: App) {
  observeAuthLayout(app.config.globalProperties.$router)
  observeRouteErrors(app.config.globalProperties.$router)
}

export function observeAuthLayout(router: Router) {
  router.beforeResolve(async (to, from, next) => {
    const isLeavingAuthenticatedGarden = find(from.matched, {name: ROUTE_NAME_AUTHENTICATED_ROOT})
    const isEnteringPublic = find(to.matched, {name: ROUTE_NAME_NOT_AUTHENTICATED_ROOT})

    if (isLeavingAuthenticatedGarden && isEnteringPublic) {
      createLogger().debug("@/enduser/setup/setupRouting", "Auto-logging out due layout change.")
      await logout()
    }

    next()
  })
}

export function observeRouteErrors(router: Router) {
  // if we do a deployment while a user has Cadence open, router navigations could fail due to file hash changing.
  // if this happens, prompt the user to do a page refresh, using the route they were trying to navigate to.
  router.onError((err, to) => {
    if (
      (err instanceof TypeError && err.message.includes("Failed to fetch dynamically imported module")) ||
      err.message.includes("not a valid JavaScript MIME type") ||
      err.message.includes("Unable to preload")
    ) {
      const refreshPageCta = `
      <div class="mb-2"> ${t("layout:recent_update_section.refresh_page_cta")} </div>`
      const refreshPageCtaLink = `
      <div>
        <a href="${to.fullPath}">${t("layout:recent_update_section.refresh_page_cta--link")}</a>
      </div>`

      useNotificationsStore().info(
        `<div class="mb-2">${t("layout:recent_update_section.title")}</div>
                ${t("layout:recent_update_section.text", {
                  refreshPageCta: refreshPageCta,
                  refreshPageCtaLink: refreshPageCtaLink,
                })}</div>
                `,
        {html: true, auto_close: null},
      )
    }
  })
}
