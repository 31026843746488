<template>
  <Teleport to="#modal-teleport-destination">
    <div
      ref="modalEl"
      class="modal fade"
      :class="modalClass"
      v-on="{
        'show.bs.modal': onShow,
        'shown.bs.modal': onShown,
        'hide.bs.modal': onHide,
        'hidden.bs.modal': onHidden,
      }"
      tabindex="-1"
      aria-hidden="true">
      <div
        class="modal-dialog"
        :class="{'modal-sm': size === 'sm', 'modal-lg': size === 'lg', 'modal-xl': size === 'xl', 'modal-fullscreen h-100': fullscreen}"
      >
        <div class="modal-content">
          <div v-if="header" class="modal-header">
            <slot name="title">
              <h5 class="modal-title">
                <slot name="title-text"></slot>
              </h5>
            </slot>

            <button type="button" class="btn-close align-self-start" aria-label="Close" @click="hide()"></button>
          </div>

          <div class="modal-body overflow-x-auto">
            <slot></slot>
          </div>

          <div v-if="$slots.footer" class="modal-footer"
               :class="{'modal-footer--sticky': stickyFooter}">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type {Ref} from "vue"
import {onBeforeUnmount, onMounted, ref} from "vue"
import {Modal} from "bootstrap"

const props = withDefaults(
  defineProps<{
    size?: "sm" | "lg" | "xl"
    backdrop?: boolean | "static"
    mountAsVisible?: boolean
    keyboard?: boolean
    modalClass?: string,
    fullscreen?: boolean,
    header?: boolean,
    stickyFooter?: boolean
  }>(),
  {
    header: true,
    backdrop: true,
    keyboard: true,
    mountAsVisible: false,
    fullscreen: false,
    stickyFooter: false
  }
)

const modalEl: Ref<Element | null> = ref(null)
const modal: Ref<Modal | null> = ref(null)
const visible = ref(props.mountAsVisible)

onMounted(() => {
  if (!modalEl.value) {
    return
  }

  modal.value = new Modal(modalEl.value, {
    backdrop: props.backdrop,
    keyboard: props.keyboard
  })

  if (visible.value) {
    show()
  }
})

onBeforeUnmount(async () => {
  if (visible.value) {
    modal.value?.hide()
    await waitForHiddenEvent()
  }
  modal.value?.dispose()
})

const show = () => {
  modal.value?.show()
  visible.value = true
}

const hide = () => {
  modal.value?.hide()
}

defineExpose({
  show,
  hide,
  visible
})

const emit = defineEmits<{
  (e: "show"): void
  (e: "shown"): void
  (e: "hide", val: Event): void
  (e: "hidden"): void
}>()

const onShow = () => {
  emit("show")
}

const onShown = () => {
  emit("shown")
}

const onHide = (event: Event) => {
  emit("hide", event)
}

const onHidden = () => {
  emit("hidden")
  visible.value = false
}

const waitForHiddenEvent = () => {
  return new Promise<void>(resolve => {
    const listener = () => {
      modalEl.value?.removeEventListener("hidden.bs.modal", listener)
      resolve()
    }
    modalEl.value!.addEventListener("hidden.bs.modal", listener)
  })
}
</script>

<style>
.modal-content {
  margin-bottom: 30vh;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit; /* [1] */
  z-index: 1055; /* [2] */
}
</style>
