import axios from "axios"

import {stringifyQuery} from "vue-router"
import {type Router} from "vue-router"
import {usePendingRequestsStore} from "@/library/stores/pending-requests"
import {useUserStore} from "@/library/stores/user"
import {MAINTENANCE_MODE_NAME} from "@/router"
import {useNotificationsStore} from "@/library/stores/notifications"
import type {App} from "vue"
import {createLogger} from "@/library/domain/logger"
import i18next from 'i18next'
import {AUTH_LOGIN_NAME} from '@/auth/routes'

const setupAxios = (app: App, router: Router) => {
  const apiBaseURL = import.meta.env.VITE_API_BASE_URL

  configureAxiosDefaults(apiBaseURL)
  configureAxiosAuthInterceptors(app, router)
  configureAxiosLoadingInterceptors()
  configureAxiosMaintenanceModeInterceptor(router)
  configureAxios403Interceptor()
}

const configureAxiosDefaults = (apiBaseURL: string) => {
  axios.defaults.baseURL = apiBaseURL
  axios.defaults.withCredentials = false
}

const configureAxiosAuthInterceptors = (app: App, router: Router) => {
  const userStore = useUserStore()
  axios.interceptors.request.use(config => {
    const token = userStore.getToken()
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    return config
  })
  axios.interceptors.response.use(
    async response => {
      const userStore = useUserStore()
      try {
        userStore.refreshTokenExpiration() // on successful requests, bump token expiration starting now
      } catch (e) {
        createLogger().warn(
          "@client/enduser/setup/setupAxios",
          "Unable to refresh user token on request fulfillment.",
          {
            extra: {
              response,
              user: userStore.user,
            },
          },
        )
      }

      return response
    },
    async error => {
      if (error?.response?.status === 401 && error?.response?.data?.message === "tfa_required") {
        return Promise.reject(error)
      }

      if (error?.response?.status === 401 && error?.response?.data?.message === "trial_expired") {
        return Promise.reject(error)
      }

      if (error?.response?.status === 401 && error?.response?.data?.tfa_error === "invalid_tfa_code") {
        return Promise.reject(error)
      }

      if (error?.response?.status === 401 || error?.response?.status === 419) {
        userStore.removeToken()
        if (router.currentRoute.value.name !== AUTH_LOGIN_NAME) {
          location.replace(`/auth/login?${stringifyQuery({
            to: router.currentRoute.value.path,
            info_message: i18next.t("auth:login_section.text--session_expired")
          })}`)
        }
      }
      return Promise.reject(error)
    },
  )
}

const configureAxiosLoadingInterceptors = () => {
  const pendingRequestsStore = usePendingRequestsStore()

  axios.interceptors.request.use(
    config => {
      pendingRequestsStore.increment()
      return config
    },
    error => {
      pendingRequestsStore.decrement()
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    response => {
      pendingRequestsStore.decrement()
      return response
    },
    error => {
      pendingRequestsStore.decrement()
      return Promise.reject(error)
    },
  )
}

const configureAxiosMaintenanceModeInterceptor = (router: Router) => {
  axios.interceptors.response.use(
    async response => response,
    async error => {
      if (error?.response?.status === 503) {
        await router.replace({name: MAINTENANCE_MODE_NAME})
      }
      return Promise.reject(error)
    },
  )
}

const configureAxios403Interceptor = () => {
  axios.interceptors.response.use(
    async response => response,
    async error => {
      if (error?.response?.status === 403) {
        useNotificationsStore().info(
          `
            <div class="mb-3">Something went wrong.</div>
            <div>If you're stuck, try
            <a href="/">going home</a> 
            or 
            <a href="http://help.cadenceco.com" target="_blank">contacting support</a>.</div>
            `,
          {
            html: true,
            auto_close: false,
          },
        )
      }
      return Promise.reject(error)
    },
  )
}

export default setupAxios
