import {createRouter, createWebHistory, type RouteLocation} from "vue-router"
import * as executorAssistantRoutes from "@/executor-assistant/routes"
import * as settingsRoutes from "@/settings/routes"
import * as authRoutes from "@/auth/routes"
import {magicLinks as executorAssistantMagicLinks} from "@/executor-assistant/domain/magic-links/magic-links"
import {authGuard} from "@/library/router-guards/authGuard"
import {createHostGuardFor} from "@/library/router-guards/hostGuard"

export const MAINTENANCE_MODE_NAME = "maintenance-mode"
export const STYLE_GUIDE_NAME = "style-guide"
export const ROUTE_NAME_AUTHENTICATED_ROOT = "authenticated-root"
export const ROUTE_NAME_NOT_AUTHENTICATED_ROOT = "not-authenticated-root"
export const ROUTE_NAME_CASES = "cases"
export const ROUTE_NAME_ACTIVE_CASE = "active-case"
export const ROUTE_NAME_NOT_FOUND = "not-found"

const hostGuard = createHostGuardFor(import.meta.env.VITE_DOMAINS?.split(",") || [])

const routes = [
  {
    path: "/",
    name: ROUTE_NAME_AUTHENTICATED_ROOT,
    component: () => import("@/layouts/AuthenticatedLayout.vue"),
    beforeEnter: [hostGuard, authGuard],
    redirect: {name: ROUTE_NAME_CASES},
    children: [
      {
        path: "settings",
        name: settingsRoutes.SETTINGS_BASE_NAME,
        component: () => import("@/layouts/SettingsLayout.vue"),
        children: settingsRoutes.SETTINGS_ROUTES,
      },
      {
        path: "cases",
        component: () => import("@/layouts/CasesLayout.vue"),
        name: ROUTE_NAME_CASES,
        meta: {ignoreMetrics: true},
        children: [
          {
            path: ":caseId",
            name: ROUTE_NAME_ACTIVE_CASE,
            meta: {ignoreMetrics: true},
            component: () => import("@/layouts/ActiveCaseLayout.vue"),
            children: [
              {
                path: "executor-assistant",
                name: executorAssistantRoutes.EXECUTOR_ASSISTANT_BASE_NAME,
                component: () => import("@/layouts/ExecutorAssistantLayout.vue"),
                children: executorAssistantRoutes.EXECUTOR_ASSISTANT_ROUTES,
                alias: "/executor-assistant/cases/:caseId", // preserve old route structure
              },
            ],
          },
        ],
      },

      ...executorAssistantMagicLinks,
    ],
  },
  {
    path: "/",
    name: ROUTE_NAME_NOT_AUTHENTICATED_ROOT,
    component: () => import("@/layouts/NotAuthenticatedLayout.vue"),
    beforeEnter: [hostGuard],
    children: [
      {
        path: "/auth",
        name: authRoutes.AUTH_BASE_NAME,
        children: authRoutes.AUTH_ROUTES,
        component: () => import("@/layouts/AuthLayout.vue"),
      },
      {
        path: "/invitation",
        name: authRoutes.AUTH_CHECK_INVITATION_NAME,
        component: () => import("@/auth/pages/CheckInvitationPage.vue"),
      },
      {
        path: "/i/:shortToken",
        name: authRoutes.AUTH_CHECK_SHORT_INVITATION_NAME,
        redirect: (to: RouteLocation) => {
          return {name: authRoutes.AUTH_CHECK_INVITATION_NAME, query: {token: to.params.shortToken}}
        },
      },
      {
        path: "/maintenance",
        name: MAINTENANCE_MODE_NAME,
        component: () => import("@/library/components/maintenance/MaintenanceMode.vue"),
      },
      {
        path: "styles",
        name: STYLE_GUIDE_NAME,
        component: () => import("@/library/components/themes/StyleGuide.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: ROUTE_NAME_NOT_FOUND,
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.scrollToTop === false) {
      return savedPosition ? {...savedPosition, behavior: "instant"} : undefined
    }

    if (to.hash) {
      const targetEl: HTMLDivElement | null = document.querySelector(to.hash)

      if (!targetEl) {
        return
      }

      const offset = targetEl.offsetTop - window.innerHeight / 2

      return {top: offset, behavior: "instant"}
    }

    return {
      ...(savedPosition ?? {top: 0}),
      behavior: "instant",
    }
  },
})

export default router
