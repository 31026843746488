import axios from "axios"
import {defineStore} from "pinia"
import {
  endsWith,
  filter,
  flatMap,
  get,
  includes,
  isEmpty,
  map,
  pick,
  pickBy,
  pull,
  range,
  set,
  slice,
  some,
} from "lodash"
import type {IAppField} from "@/library/models/app-fields/app-field.interface"
import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"
import {useAppFieldStore} from "@/library/stores/app-field"
import useActiveCase from "@/library/composables/useActiveCase"
import {
  convertKeySignatureToPath,
  extractIndicesFromSignature,
  type IAppFieldKeySet,
  type IRepeatableAppFieldKeySets,
} from "@/library/domain/app-fields/label-maps"
import {createLogger} from "@/library/domain/logger"
import type {ICaseQuestionnaireSession} from "@/library/models/case-questionnaire-session.interface"

export interface State {
  appFieldValues: Record<string, any>
  appFieldValuesFetchPromise: null | Promise<any>
}

export const useAppFieldValueStore = defineStore("app-field-value", {
  state: (): State => ({
    appFieldValues: {},
    appFieldValuesFetchPromise: null,
  }),
  getters: {
    isLoaded(): boolean {
      return !isEmpty(this.appFieldValues)
    },
  },
  actions: {
    hasAppFieldValuesForSignature(signature: TAppFieldValueKey["signature"]) {
      return !!this.getAppFieldValuesForSignature(signature)
    },

    getAppFieldValuesForSignature(signature: TAppFieldValueKey["signature"]) {
      return get(this.appFieldValues, signature, null)
    },

    removeAppFieldValuesForSignature(signature: TAppFieldValueKey["signature"]) {
      const val = this.getAppFieldValuesForSignature(signature)
      set(this.appFieldValues, signature, undefined)

      return val
    },

    removeRepeatableAppFieldValueFor(keySet: IAppFieldKeySet, repeatableKeySet: IRepeatableAppFieldKeySets) {
      const val = this.getAppFieldValuesForSignature(keySet.id)
      const list = this.getAppFieldValuesForSignature(slice(repeatableKeySet.repeatable_by, 0, -3).join(""))
      pull(list, val)
      return val
    },

    discoverAppFieldValuesFor(keySet: IAppFieldKeySet) {
      return pick(this.appFieldValues, map(keySet.keys, "signature"))
    },

    hasAppFieldValuesFor(keySet: IAppFieldKeySet) {
      return some(keySet.keys, ({signature}) => get(this.appFieldValues, signature) !== undefined)
    },

    async _listAppFieldValues(caseId: number, path: string | null = null) {
      if (!caseId) {
        throw new Error("Unable to fetch app field values without caseId")
      }

      let params = {}
      if (path) {
        params = {path}
      }
      await axios
        .get(`/v3/enduser/cases/${caseId}/app-field-values`, {params})
        .then(response => {
          const appFieldValues = this.$state.appFieldValues ? this.$state.appFieldValues : {}
          this.$patch({
            appFieldValues: response.data.data
              ? path
                ? set(appFieldValues, path, response.data.data)
                : response.data.data
              : appFieldValues,
          })
        })
        .catch(_ => {
          const appFieldValues = this.$state.appFieldValues ? this.$state.appFieldValues : {}
          this.$patch({
            appFieldValues: path ? set(appFieldValues, path, null) : {},
          })
        })
    },

    async pullAppFieldValuesFor(path: IAppField["path"] | null = null) {
      return await this._listAppFieldValues(useActiveCase().id, path)
    },

    pullAppFieldValues(useCache = true) {
      if (!this.appFieldValuesFetchPromise || !useCache) {
        this.appFieldValuesFetchPromise = this.pullAppFieldValuesFor()
      }

      return this.appFieldValuesFetchPromise
    },

    async pushAppFieldValues(
      data: any,
      deleted?: TAppFieldValueKey["signature"][],
      session_id?: ICaseQuestionnaireSession["id"],
      hydrateFromResponse = true,
      api = async (data: {
        data?: Record<string, any>
        path?: TAppFieldValueKey["signature"]
        delete?: TAppFieldValueKey["signature"][]
        session_id?: ICaseQuestionnaireSession["id"]
      }) => (await axios.put(`/v3/enduser/cases/${useActiveCase().id}/app-field-values`, data)).data,
    ) {
      deleted = deleted?.length ? (map(deleted, convertKeySignatureToDotNotation) as string[]) : deleted

      const payload = pickBy({delete: deleted, data, session_id}, x => x !== undefined)
      const response = await api(payload)

      if (hydrateFromResponse) {
        this.appFieldValues = response.data
      }
    },

    getCaseName(): string | null {
      const deceased = this.getAppFieldValuesForSignature("deceased.name")

      return deceased ? `${deceased.first} ${deceased.last}` : null
    },
  },
})

export const convertKeySignatureToDotNotation = (s?: TAppFieldValueKey["signature"]) =>
  s?.replace(/\[(\d+)]/g, (_, num) => `.${num}`)

export function getAppFieldValueForTypeFromFullPath(
  appFieldValues: Object | null,
  path: string,
  index: number | null,
): string | null {
  if (index || index === 0) {
    return get(appFieldValues, [path, index, "type"])
  }
  return null
}

export const generateCollectionKeysWithin = (range_: number, collectionPath: string): string[] =>
  range(0, range_).map((i: number) => `${collectionPath}[${i}]`)

export const generateCollectionKeysVia = (context: any, collectionPath: string, shouldSkipEmpty = false): string[] => {
  const size = (get(context, collectionPath) || []).length || +!shouldSkipEmpty
  return generateCollectionKeysWithin(size, collectionPath)
}

// todo: while still handling non-collection at tail, also add flag for:
//       - deceased.employment.status[*] <-- list of strings
export function inflateNestedCollectionKeysByLengthOnto(
  nestedCollections: string[] = [],
  context: any,
  basePath = "",
): string[] {
  const collectionPath = `${basePath ? `${basePath}.` : ""}${nestedCollections[0]}`

  if (nestedCollections.length === 1) {
    return [collectionPath] // bail; no more nesting
  }

  if (nestedCollections.length === 0) {
    return [basePath] // bail; no more nesting
  }

  const collection = generateCollectionKeysVia(context, collectionPath)
  return flatMap(collection, key => inflateNestedCollectionKeysByLengthOnto(nestedCollections.slice(1), context, key))
}

export type TAppFieldValueKey = {
  signature: string
  model: IAppField
  indices?: number[] | null
  isList: boolean
}

export function inflateNestedCollectionKeysFrom(labels: LabelMap, values: any): TAppFieldValueKey[] {
  return flatMap([...labels.keys()], path => {
    const nestedCollections = path.split(/\[\*]\.?/g)
    const nestedCollectionsWithoutEmpties = filter(nestedCollections) // todo: fix regex to mitigate this

    const collectionSignatures = inflateNestedCollectionKeysByLengthOnto(nestedCollectionsWithoutEmpties, values)
    return map(collectionSignatures, signature => createKeyFromSignatureAndPath(signature, path))
  })
}

/** @deprecated - use `createKeyFromSignature(sig)` */
export function createKeyFromSignatureAndPath(
  signature: TAppFieldValueKey["signature"],
  path: IAppField["path"],
): TAppFieldValueKey {
  return {
    signature,
    model: useAppFieldStore().appFieldsByPath[path],
    indices: extractIndicesFromSignature(signature),
    isList: isList(path),
  }
}

export function createKeyFromSignature(signature: TAppFieldValueKey["signature"]): TAppFieldValueKey {
  const appFieldsByPath = useAppFieldStore().appFieldsByPath
  const model =
    appFieldsByPath[convertKeySignatureToPath(signature)] ??
    // defaulting to list app-field, provides the ability to render UI for multiple selections
    appFieldsByPath[`${signature}[*]`]

  if (!model) {
    createLogger().error("library/stores/app-field-value", "Unable to find app field for signature", {
      extra: {signature},
    })
  }

  return {
    signature,
    model,
    indices: extractIndicesFromSignature(signature),
    isList: isList(model.path),
  }
}

/**
 * This is a low-level API helper; please verify that your path is not top-level before leveraging this helper.
 * For robustness ensure to have full-fledged IAppField.
 *
 * Rather than blindly calling with `isList(convertKeySignatureToPath(key_sig)`
 * Recommended usage is with `isList(findAppFieldBySignature(key_sig).path)`
 * @param path
 */
export function isList(path: IAppField["path"]) {
  return endsWith(path, "[*]")
}

export function isOfList(path: IAppField["path"]) {
  return includes(path, "[*]")
}

export function findAppFieldBySignature(signature: TAppFieldValueKey["signature"]) {
  const appFieldsByPath = useAppFieldStore().appFieldsByPath
  // defaulting to list app-field, provides the ability to render UI for multiple selections
  return appFieldsByPath[convertKeySignatureToPath(signature)] ?? appFieldsByPath[`${signature}[*]`]
}
